.Loading-Container{
  background-color: #0E4F79;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  img{
    width: 100px;
    height: auto;
  }
}
@media (max-width: 768px) {
  .Loading-Container{
    img{
      width: 80px;
    }
  }
}
.unload{
  animation-name: opacity-off;
  animation-duration: 0.7s;
}
@keyframes load-off {
  0% {opacity: 1;}
  100% {opacity: 0;}
}