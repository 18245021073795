.Show-up{
    animation-name: opacity-on;
    animation-duration: 0.8s;
}
.Show-down{
    animation-name: opacity-off;
    animation-duration: 0.3s;
}

@keyframes opacity-on {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes opacity-off {
    0% {opacity: 1;}
    100% {opacity: 0;}
}


.Nav-Header{
    position: fixed;
    padding-top: 40px;
    height: 20px;
    width: 100%;
    z-index: 1056;
    .Nav-Container{
        display: flex;
        justify-content: space-between;
        height: 100px;
        margin: 0 auto;
        padding: 0 90px;
        background-color:unset;
        .Nav-Logo{
            display: flex;
            align-items: center;
            width: 100px;
            .Nav-Title{
                mix-blend-mode: difference;
                margin-left: 17px;
                font-size: 26px;
                margin-bottom: 0;
                letter-spacing: 6px;
                color: #3C3C3B;
                font-family: RobotoLight;
            }
            .Nav-Title.actived{
                color: white;
                transition: 0.6s;
            }
            .Logo{
                height: 100%;
            }
            .Logo2{
                transition: 1s;
                height: 100%;
            }
        }
        
        .Nav-Hamburger{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            .Hamburger-btn{

                width: 114px;
                height: 3px;
                background-color: #3C3C3B;
                border-radius: 5px;
                margin-bottom: 10px;
                transition: 0.6s;
            }
            .Hamburger-btn-2{
                
                justify-content: end;
                width: 67px;
                height: 3px;
                background-color: #3C3C3B;
                border-radius: 5px;
                transition: 0.6s;
            }
            .Hamburger-btn.active{
                -moz-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                background-color: white;
                width: 67px !important;
                margin: 0px !important;
            }
            .Hamburger-btn-2.active{
                background-color: white;
                -moz-transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                position: absolute;
            }
        }
        .Nav-Hamburger.actived{
            display: none;
        }
    }
}
.Nav-Header.actived{
    mix-blend-mode: normal;
}
.modal-backdrop{
    background-color: transparent !important;
}
    .modal-dialog{
        max-width: none !important;
        margin: unset !important;
        transition: 0.6s linear 0.4s;
        .modal-content{
            background-clip: unset;
            border: none;
            border-radius: unset;
            height: 100vh;
            background-color: #0E4F79;
            color: white;
            .modal-body{
                .Nav-Container{
                    padding: 235px 137px 0px 120px;
                    display: flex;
                    justify-content: space-between;
                    .Left-Section{
                        display: flex;
                        flex-direction: column;
                        width: 325px;
                        .accordion-item{
                            border: none;
                            .header-accordion{
                                button{
                                    background-color: #0E4F79;
                                }
                                button:after{
                                    display: none;
                                }
                            }
                            .accordion-body{
                                background-color: #0E4F79;
                                border: none;
                            }
                        }
                        
                        
                        .Left-Section-Tools{
                            display: flex;
                            background-color: transparent;
                            border: none;
                            box-shadow: none;
                            align-items: center;
                            p{
                                cursor: pointer;
                                pointer-events: all;
                                font-family: Roboto;
                                font-size: 24px;
                                margin: unset;
                                letter-spacing: 1px;
                            }
                            .Menu-Content-Tools{
                                display: inline-flex;
                                align-items: center;
                                padding: 10px 20px;
                                .Plus1{
                                    position: absolute;
                                    width: 15px;
                                    height: 2px;
                                    background-color: white;
                                    border-radius: 5px;
                                    margin: 0px;
                                }
                                .Plus2{
                                    transform: rotate(0deg);
                                    width: 15px;
                                    height: 2px;
                                    background-color: white;
                                    border-radius: 5px;
                                    transition: 0.8s;
                                }
                                
                                .Plus2.actived{
                                    transform: rotate(90deg);
                                    height: 2px;
                                    position: absolute;
                                    margin: 0px;
                                }
                            }
                       
                        }

                        .Menu-Content{
                            p{
                                line-height: 40px;
                                font-family: Roboto;
                                font-size: 24px;
                                padding-left: 45px;
                                margin-bottom: 0px;
                                cursor: pointer;
                            }
                        }
                        .Menu-Content.collapsing {
                            -webkit-transition-delay: 0.6s;
                            transition-delay: 0.8s;
                            transition: height 0.8s ;
                          }
                        
                        
                    }
                    .Middle-Section{
                        padding-top: 7px;
                        .Title-Section{
                            p{
                                margin: 0px;
                                font-family: Roboto;
                                font-size: 24px;
                                font-weight: 300;
                            }
                        }
                        .Social-Logo-Section{
                            padding-top: 19px;
                            img{
                                padding-right: 19px;
                            }
                        }
                    }
                    .Right-Section{
                        p{
                            letter-spacing: 10px;
                            font-size: 32px;
                        }
                    }
                }
            }
            .modal-footer{
                justify-content: space-between;
                border-top: none;
                padding: 0px 159px 64px 100px;
                p{
                    font-size: 14px;
                    font-family: Roboto;
                    letter-spacing: 7px;
                }
            }
        }
    }

    @media (max-width: 768px){
        .Nav-Header{
            padding-top: 20px;
            .Nav-Container{
                padding: 0px 30px;
                .Nav-Logo{
                    width: 210px;
                    .Logo{
                        height: 80%;
                    }
                    .Logo2{
                        height: 80%;
                    }
                    .Nav-Title{
                        mix-blend-mode: difference;
                        margin-left: 5px;
                        font-size: 17px;
                        margin-bottom: 0;
                        letter-spacing: 4px;
                    }
                }
                .Nav-Hamburger{
                    .Hamburger-btn{
                        width: 75px;
                    }
                    .Hamburger-btn-2{
                        width: 47px;
                    }
                    .Hamburger-btn.active{
                        width: 47px !important;
                    }
                    
                }
            }
        }
        .modal{
            width: 100vw !important;
            height: 101vh !important;
            .modal-dialog{
                .modal-content{
                    height: 100vh;
                    .modal-body{
                        max-height: 66vh;
                        .Nav-Container{
                            padding: 155px 0px 0px 0px;
                            .Left-Section{
                                width: 235px;
                                .Left-Section-Tools{
                                    p{
                                        font-size: 16px;
                                    }
                                }
                                .Menu-Content{
                                    p{
                                        line-height: 40px;
                                        font-size: 16px;
                                        padding-left: 24px;
                                    }
                                }
                            }
                            .Middle-Section{
                                padding-right: 10px;
                                p{
                                font-size: 16px;  
                                letter-spacing: 1px;   
                                }                
                            }
                            
                        }
                    }
                    .modal-footer{
                        justify-content: center;
                        padding: 73px 50px 25px 50px;
                        p{
                            letter-spacing: 2px;
                        }
                    }
                }
            }
        }
        
    }


