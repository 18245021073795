@font-face {
  font-family: 'Planta';
  src: local('Planta'), url(./Fonts/Plantagenet_Cherokee.woff) format('woff');

}
@font-face{
  
  font-family: 'Roboto';
  src: local('Roboto'), url(./Fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face{
  font-family: 'RobotoLight';
  src: local('RobotoLight'), url(./Fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold'), url(./Fonts/Roboto-Bold.ttf) format('truetype');
}


body {
  margin: 0;
  font-family: Planta !important;
   
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}
@media (min-width: 768px) {
  ::-webkit-scrollbar{
    background: transparent;
    width: 10px;
  }
  ::-webkit-scrollbar-thumb{
    background: transparent;
    transition: 0.2s;
  }
  ::-webkit-scrollbar-thumb:hover{
    background: lightgray;
    border-radius: 20px;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth!important;
}