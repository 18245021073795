.home {
    padding-top: 212px;

    .section1,
    .section2,
    .section3,
    .section4,
    .section5,
    .landingpage,
    .gallery {
        width: 100%;
        height: auto;
    }

    .image {
        width: 100%;
    }

    p {
        font-size: 16px;
        line-height: 2.2;
        letter-spacing: 1.5px;
        font-family: Planta;
        text-align: justify;
        color: #0E4F79;
        margin-bottom: 0px;
    }

    a {
        font-size: 16px;
        line-height: 2.2;
        letter-spacing: 1.5px;
        text-decoration: none;
        font-family: Planta;
        color: #0E4F79;
    }

    .title{    
            font-size: 22px;
            letter-spacing: 1px;
            font-family: Roboto;
    }

    .parallax{
        width: 80%;
    }


    .react-parallax-bgimage {
        position: relative !important;
    }

    .landingpage {
        display: flex;
        padding-bottom: 212px;

        .landingpage-left {
            width: 60%;

            .imgldpage {
                width: 100%;
            }
        }

        .landingpage-right {
            width: 40%;
            padding-left: 220px;
            padding-top: 106px;

            .Right-Section-Tools {
                display: flex;
                background-color: transparent;
                border: none;
                box-shadow: none;
                align-items: center;

                p {
                    font-size: 20px;
                    font-family: Helvetica;
                    margin: unset;
                    line-height: unset;
                    letter-spacing: 1px;
                    color: #0E4F79;
                    font-family: Roboto;
                }

                .Menu-Content-Tools {
                    display: inline-flex;
                    align-items: center;
                    padding: 10px 20px;

                    .Plus1 {
                        position: absolute;
                        width: 15px;
                        height: 2px;
                        background-color: #0E4F79;
                        border-radius: 5px;
                        margin: 0px;
                    }

                    .Plus2 {
                        transform: rotate(0deg);
                        width: 15px;
                        height: 2px;
                        background-color: #0E4F79;
                        border-radius: 5px;
                        transition: 0.8s;
                    }

                    .Plus2.actived {
                        transform: rotate(90deg);
                        height: 2px;
                        position: absolute;
                        margin: 0px;
                    }
                }
            }

            .Menu-Content {
                p {
                    padding-top: 10px;
                    font-family: Helvetica;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 1.2em;
                    letter-spacing: unset;
                    padding-left: 45px;
                    cursor: pointer;
                    font-family: Roboto;
                }
            }

            .Menu-Content.collapsing {
                -webkit-transition-delay: 0.6s;
                transition-delay: 0.8s;
                transition: height 0.8s;
            }


        }
    }

    .section1 {
        display: flex;
        padding: 0px 94px;
        padding-bottom: 212px;

        .contentsec1 {
            display: flex;

            .sec1-left {
                width: 50%;
                padding-right: 20px;
                display: flex;
                justify-content: center;
            }

            .sec1-right {
                display: flex;
                align-items: center;
                width: 50%;
                padding-left: 20px;
                .TitleDiv{
                    display: flex;
                    padding-bottom: 15px;
                    .japtitle{
                        font-family: RobotoBold;
                        font-size: 22px;
                        font-weight: 700;
                        padding-left: 10px;
                    }
                }
            }
        }

    }
    .Image-Section{
        padding-bottom: 212px;
        img{
            
        width: 100%;
        }
    }

    .section2 {
        display: flex;
        padding: 0px 94px;
        padding-bottom: 212px;

        .contentsec2 {
            display: flex;

            .sec2-left {
                width: 50%;
                padding-right: 20px;
                display: flex;
                align-items: center;
                .TitleDiv{
                    display: flex;
                    padding-bottom: 15px;
                    .japtitle{
                        font-family: RobotoBold;
                        font-size: 21px;
                        font-weight: 700;
                        padding-left: 10px;
                    }
                }
            }

            .sec2-right {
                width: 50%;
                padding-left: 20px;
                display: flex;
                justify-content: center;
            }
        }

    }

    .section3 {
        display: flex;
        padding: 0px 94px;
        padding-bottom: 212px;

        .contentsec3 {
            display: flex;

            .sec3-left {
                width: 50%;
                padding-right: 20px;
                display: flex;
                justify-content: center;
                .imagerec{
                    width: 75%;
                }
                .parallax3{
                    width: 100%;
                }
            }

            .sec3-right {
                width: 50%;
                padding-left: 20px;
                display: flex;
                align-items: center;
                .TitleDiv{
                    display: flex;
                    padding-bottom: 15px;
                    .japtitle{
                        font-family: RobotoBold;
                        font-size: 21px;
                        font-weight: 700;
                        padding-left: 10px;
                    }
                }
            }
        }

    }

    .section4 {
        padding-bottom: 125px;

        .contentsec4 {
            .sec4-left {
                text-align: center;
                .TitleDiv{
                    display: flex;
                    justify-content: center;
                    padding-bottom: 15px;
                    .japtitle{
                        font-family: RobotoBold;
                        font-size: 21px;
                        font-weight: 700;
                        padding-left: 10px;
                    }
                }
                .title{
                    text-align: center;
                }
                p{
                    text-align: center;
                }
                
            }

            .sec4-right {
                text-align: center;
                .container {
                    z-index: 1;
                    perspective:3000px;
                }
                
                
                .container .book {
                position: relative;
                display: block;
                width: 400px;
                height: 550px;
                margin: 5% auto;
                border-radius: 2px 4px 4px 2px;
                background: linear-gradient(45deg,  #DAD5DC 0%, #f2ebf4 100%);
                font-family: acumin-pro, sans-serif;
                -webkit-box-shadow: 13px 13px 8px 0px rgba(151, 146, 153,0.6);
                -moz-box-shadow: 13px 13px 8px 0px rgba(151, 146, 153,0.6);
                box-shadow: 13px 13px 8px 0px rgba(151, 146, 153,0.6);
                font-weight: 400;
                color: #2b2b2b;
                -webkit-transform-style: preserve-3d;
                -moz-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transition: -webkit-transform .5s;
                -moz-transition: -moz-transform .5s;
                transition: transform .5s;
                }
                
                .container .book:hover {
                -webkit-transform: rotate3d(0,1,0,35deg);
                -moz-transform: rotate3d(0,1,0,35deg);
                transform: rotate3d(0,1,0,35deg);
                }
                
                .container .book > div,
                .container .front > div {
                display: block;
                position: absolute;
                }
                
                
                .container .front {
                -webkit-transform-style: preserve-3d;
                -moz-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-transform-origin: 0% 50%;
                -moz-transform-origin: 0% 50%;
                transform-origin: 0% 50%;
                -webkit-transition: -webkit-transform .5s;
                -moz-transition: -moz-transform .5s;
                transition: transform .5s;
                -webkit-transform: translate3d(0,0,20px);
                -moz-transform: translate3d(0,0,20px);
                transform: translate3d(0,0,20px);
                z-index: 10;
                }
                
                .container .front > div {
                width: 400px;
                height: 550px;
                }
                
                .container .left-side{
                width: 40px;
                left: -20px;
                height: 550px;
                background-color: rgb(232,229,234);
                -webkit-transform: rotate3d(0,1,0,-90deg);
                -moz-transform: rotate3d(0,1,0,-90deg);
                transform: rotate3d(0,1,0,-90deg);
                }
                
                .cover{
                img{
                    height: 100%;
                    width: 100%;
                }
                }
                
                #eye-right {
                padding-left: 185px;
                }
                
                .cover .num-up{
                position: absolute;
                top: 64px;
                left: 47px;
                letter-spacing: 50px;
                transform: rotate(-90deg);
                }
                
                .cover .num-down{
                position: relative;
                top: 65px;
                left: -95px;
                transform: rotate(-90deg);
                }
                
                .author{
                font-family: acumin-pro, sans-serif;
                font-weight: 400;
                position: absolute;
                top: 475px;
                left: 50px;
                opacity: .8;
                }
                
                .container .front > div {
                border-radius: 0 3px 3px 0;
                box-shadow: 
                    inset 4px 0 10px rgba(0, 0, 0, 0.1);
                }
                
                .container .front:after {
                content: '';
                position: absolute;
                top: 1px;
                bottom: 1px;
                left: -1px;
                width: 1px;
                }
                
                .container .cover:after {
                content: '';
                position: absolute;
                top: 0;
                left: 10px;
                bottom: 0;
                width: 3px;
                background: rgba(0,0,0,0.1);
                box-shadow: 1px 0 3px rgba(255, 255, 255, 0.1);
                }
                
                
                .container h2 {
                width: 500px;
                height: 40px;
                color: #2b2b2b;
                font-size: 15px;
                line-height: 40px;
                padding-right: 10px;
                text-align: right;
                -webkit-transform-origin: 0 0;
                -moz-transform-origin: 0 0;
                transform-origin: 0 0;
                -webkit-transform: rotate(90deg) translateY(-40px);
                -moz-transform: rotate(90deg) translateY(-40px);
                transform: rotate(90deg) translateY(-40px);
                }
                
                .cover {
                background: linear-gradient(45deg,  #DAD5DC 0%, #f2ebf4 100%);
                }
                
                
                .left-side h2 span:first-child {
                font-weight: 400;
                font-size: 13px;
                padding-right: 20px;
                }
                
                .left-side h2 span:last-child{
                font-family: acumin-pro, sans-serif;
                }
                .container.Desktop{
                    display: block;
                }
                .container.Mobile{
                    display: none ;
                }
            }
        }
    }

    .section5 {
        justify-content: center;
        display: flex;
        padding-bottom: 202px;
        letter-spacing: 2.5px;

        .contentsec5 {
            .contact {
                text-align: center;
                margin-bottom: 50px;

                p {
                    font-size: 22px;
                    text-align: center;
                    font-family: Roboto;
                    letter-spacing: 1px;
                }
                .TitleDiv{
                    display: flex;
                    justify-content: center;
                    padding-bottom: 15px;
                    .japtitle{
                        font-family: RobotoBold;
                        font-size: 21px;
                        font-weight: 700;
                        padding-left: 10px;
                    }
                }
            }

            .content {
                font-size: .9em;
                width: 70rem;
                display: flex;
                flex-direction: column;

                .add-padding{
                    padding-top: 50px;
                }

                p {
                    font-size: 16px;
                }

                .content-top {
                    display: flex;
                    justify-content: space-between;
                }

                .content-midle {
                    display: flex;
                    justify-content: space-between;
                }

                .content-bottom {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    .gallery{
        padding-bottom: 100px;
        .contentgallery{
            padding: 0px 94px;
            p{
                text-align: center;
                font-family: Roboto;
            }
            .titlegallery{
                margin-bottom: 50px;
                .TitleDiv{
                    display: flex;
                    justify-content: center;
                    padding-bottom: 15px;
                    .japtitle{
                        font-family: RobotoBold;
                        font-size: 21px;
                        font-weight: 700;
                        padding-left: 10px;
                    }
                }
            }
            .rows{
                justify-content: center;
                display: flex;
                .items{
                    text-align: center;
                    display: block;
                    width: 30%;
                    padding-bottom: 50px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .home {
        .title {
                font-size: 20px;
                margin-bottom: 0px;     
        }

        p {
            font-size: 14px;
        }

        .landingpage {
            .landingpage-left {
                width: 70%;
                overflow: hidden;

                .imgldpage {
                    width: auto;
                    position: relative;
                    right: 195%;
                }
            }

            .landingpage-right {
                padding-left: unset;
                display: none;
            }
        }

        .section1 {
            padding: unset;
            padding-bottom: 60px;

            .contentsec1 {
                padding: 0vw 12vw;
                flex-direction: column;
                overflow: overlay;

                .sec1-left {
                    width: 100%;
                    padding-right: unset;
                }

                .sec1-right {
                    width: 100%;
                    padding-left: unset;
                    padding-top: unset;
                    margin-top: 1.6em;
                }
            }
        }

        .section2 {
            padding: unset;
            padding-bottom: 60px;

            .contentsec2 {
                padding: 0vw 12vw;
                flex-direction: column-reverse;
                overflow: overlay;

                .sec2-left {
                    width: 100%;
                    padding-right: unset;
                    padding-top: unset;
                    margin-top: 1.6em;
                }

                .sec2-right {
                    width: 100%;
                    padding-left: unset;
                }
            }
        }

        .section3 {
            padding: unset;

            .contentsec3 {
                padding: 0vw 12vw;
                flex-direction: column;
                overflow: overlay;

                .sec3-left {
                    width: 100%;
                    padding-right: unset;
                }

                .sec3-right {
                    width: 100%;
                    padding-left: unset;
                    padding-top: unset;
                    margin-top: 1.6em;
                    .TitleDiv{
                        flex-direction: column;
                        .japtitle{
                            padding-left: 0px;
                        }
                    }
                }
            }
        }

        .section4 {
            padding-top: unset;
            padding-top: 98px;

            .contentsec4 {
                padding: 0vw 12vw;
                display: flex;
                flex-direction: column-reverse;
                overflow: overlay;

                .sec4-left {
                    margin-top: 1.6em;
                }

                .sec4-right{
                    .container.Desktop{
                        display: none;
                    }
                    .container.Mobile{
                        display: block;
                    }
                }
                img {
                    width: 100%;
                }
            }
        }

        .section5 {
            padding-top: 98px;
            padding-bottom: 98px;

            .contentsec5 {
                overflow: overlay;

                .contact {
                    margin-bottom: 20px;

                    p {
                        font-size: 20px;
                    }
                }

                .content {
                    width: unset;

                    .content-top {
                        flex-direction: column;
                        align-items: center;
                    }

                    .content-midle {
                        flex-direction: column;
                        align-items: center;
                    }

                    .content-bottom {
                        flex-direction: column;
                        align-items: center;
                    }

                    p {
                        font-size: 14px;
                        line-height: 60px;
                        letter-spacing: 3px;
                    }

                    a{
                        font-size: 14px;
                    }
                }
            }
        }

        .gallery{
            padding-bottom: 100px;
            .contentgallery{
                padding: 0vw 12vw;
                .rows{
                    display: block;
                    .items{
                        width: 100%;
                    }
                }
            }
        }
    }
}