.Footer-Container{
    height: 100vh;
    background-color: #0E4F79;
    position: relative;
    .Footer-Wraper{
        padding-top: 44px;
        display: flex;
        flex-direction: column;
        .Footer-Content-Wraper{
            padding: 210px 154px 0px 135px;
            height: 78vh;
            display: flex;
            justify-content: space-between;
            .Footer-Left-Section{
                display: flex;
                flex-direction: column;
                width: 325px;
                .Left-Section-Tools{
                    display: flex;
                    background-color: transparent;
                    border: none;
                    box-shadow: none;
                    align-items: center;
                    p{
                        font-family: Roboto;
                        font-size: 24px;
                        margin: unset;
                        letter-spacing: 1px;
                    }
                    .Menu-Content-Tools{
                        display: inline-flex;
                        align-items: center;
                        padding: 10px 20px;
                        .Plus1{
                            position: absolute;
                            width: 15px;
                            height: 2px;
                            background-color: white;
                            border-radius: 5px;
                            margin: 0px;
                        }
                        .Plus2{
                            transform: rotate(0deg);
                            width: 15px;
                            height: 2px;
                            background-color: white;
                            border-radius: 5px;
                            transition: 0.8s;
                        }
                        
                        .Plus2.actived{
                            transform: rotate(90deg);
                            height: 2px;
                            position: absolute;
                            margin: 0px;
                        }
                    }
               
                }

                .Menu-Content{
                    p{
                        font-family: Roboto;
                        font-size: 24px;
                        padding-left: 45px;
                        margin-bottom: 0px;
                        line-height: 40px;
                        color: white;
                        cursor: pointer;
                    }
                }
                .Menu-Content.collapsing {
                    -webkit-transition-delay: 0.6s;
                    transition-delay: 0.8s;
                    transition: height 0.8s ;
                  }
            }
            .Footer-Midle-Section{
                padding-top: 7px;
                .Footer-Title-Section{
                    p{
                        color: white;
                        margin: 0px;
                        font-family: Roboto;
                        font-size: 24px;
                        font-weight: 300;
                    }
                }
                .Footer-Social-Logo-Section{
                    padding-top: 19px;
                    img{
                        padding-right: 19px;
                    }
                    .Wa-Logo-Section{
                        display: flex;
                        padding-top: 12px;
                        flex-direction: column;
                        p{
                            color: white;
                            margin: 0px;
                            font-family: Roboto;
                            font-size: 16px;
                            font-weight: 300;
                        }
                        .Wa-Link{
                            display: flex;
                            flex-direction: row;
                            padding-top: 4px;
                            img{
                                width: 50px;
                                height: 30px;
                            }
                            p{
                                color: white;
                                margin: 0px;
                                font-family: Roboto;
                                font-size: 14px;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
            .Footer-Right-Section{
                p{
                    font-family: Roboto;
                    color: white;
                    letter-spacing: 10px;
                    font-size: 32px;
                }
            }
        }
        .Footer-Lower-Section{
            justify-content: space-between;
            padding: 35px 120px 0px 100px;
            display: flex;
            p{
                color: white;
                font-size: 14px;
                font-family: Roboto;
                letter-spacing: 7px;
                margin: unset;
            }
        }
    }
    
}

@media (max-width: 768px) {
    .Footer-Container{
        .Footer-Wraper{
            .Footer-Content-Wraper{
                padding: 155px 16px 0px 16px;
                height: 80vh;
                .Footer-Left-Section{
                    width: 235px;
                    .Left-Section-Tools{
                        p{
                            font-size: 16px;
                        }
                    }
                    .Menu-Content{
                        padding-top: unset;
                        padding-left: unset;
                        letter-spacing: unset;
                        p{
                            font-size: 16px;
                            padding-left: 24px;
                        }
                    }
                }
                .Footer-Midle-Section{
                    padding-right: 10px;
                    .Footer-Title-Section{
                        p{
                            font-size: 16px;
                            letter-spacing: 0px;
                        }
                    }
                }
            }
            .Footer-Lower-Section{
                flex-direction: column;
                padding: unset;
                padding-top: 3px;
                align-items: center;
                p{
                    font-size: 14px;
                    letter-spacing: 2px;
                    margin: 4px;
                }
            }
        }
    }
}